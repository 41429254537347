// Generated by CoffeeScript 1.9.2
var compareProperty, dot;

module.exports = function(collection, expressions) {
  return collection.sort(function(a, b) {
    var expression, i, len, predicate, reverse, value;
    for (i = 0, len = expressions.length; i < len; i++) {
      expression = expressions[i];
      if (typeof expression === 'object') {
        predicate = expression.predicate;
        reverse = expression.reverse;
      } else {
        predicate = expression;
      }
      value = compareProperty(predicate, reverse)(a, b);
      if (value !== 0) {
        return value;
      }
    }
  });
};

dot = {
  get: function(obj, field) {
    var i, key, keys, len, value;
    keys = field.split('.');
    value = obj;
    for (i = 0, len = keys.length; i < len; i++) {
      key = keys[i];
      value = value[key];
    }
    return value;
  },
  set: function(obj, field, setValue) {
    var allButLastKey, i, key, keys, lastKey, len, value;
    keys = field.split('.');
    allButLastKey = keys.slice(0, -1);
    lastKey = keys[keys.length - 1];
    value = obj;
    for (i = 0, len = allButLastKey.length; i < len; i++) {
      key = allButLastKey[i];
      value = value[key] != null ? value[key] : value[key] = {};
    }
    return value[lastKey] = setValue;
  }
};

compareProperty = function(predicate, reverse) {
  var getter;
  getter = typeof predicate === 'function' ? function(obj) {
    return predicate(obj);
  } : function(obj) {
    return dot.get(obj, predicate);
  };
  getter;
  if (!reverse) {
    return function(a, b) {
      if (getter(a) < getter(b)) {
        return -1;
      } else if (getter(a) > getter(b)) {
        return 1;
      } else {
        return 0;
      }
    };
  } else {
    return function(a, b) {
      if (getter(a) > getter(b)) {
        return -1;
      } else if (getter(a) < getter(b)) {
        return 1;
      } else {
        return 0;
      }
    };
  }
};
